import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import * as Sentry from "@sentry/react";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "src/stores/RootStore";
import { UI } from "@wwimmo/ui";
import { useTranslation } from "react-i18next";
import { Route, selectRoute } from "src/config/routes";
import { LinkContainer } from "react-router-bootstrap";
import { isEmpty } from "lodash";
import { Role } from "src/network/User";
import { useSidebarFunctions } from "src/hooks/sidebar-functions/useSidebarFunctions";
import { sidebarWidthUnit } from "src/stores/UIStore";
import { NetworkConfig } from "src/network/NetworkConfig";
import { Link } from "react-router-dom";
import { SIDEBAR_MENU_PATH } from "src/stores/UIStore";
import { useLocation } from "react-router-dom";
import styles from "./Sidebar.module.css";
import { useRef } from "react";

export const SidebarBase = () => {
    const { uiStore, authStore, navStore, ticketStore } = useContext(RootStoreContext);

    const { t } = useTranslation();

    const location = useLocation();

    const [hasInitialized, setHasInitialized] = useState<boolean>(false);
    const [activeSidebarMenuBottomKey, setActiveSidebarMenuBottomKey] = useState<SIDEBAR_MENU_PATH>(
        location.pathname === Route.customerusers ||
            location.pathname === Route.tileAdministration ||
            location.pathname === Route.easycontactsettings ||
            location.pathname === Route.ticketingsettings ||
            location.pathname === Route.theming ||
            location.pathname === Route.agendaTemplate ||
            location.pathname === Route.portalDemo
            ? SIDEBAR_MENU_PATH.SETTINGS
            : location.pathname === Route.manager + Route.servicesCostsAndInvoicesOverview ||
              location.pathname === Route.manager + Route.servicesCostsAndInvoicesCurrentMonth ||
              location.pathname === Route.manager + Route.servicesCostsAndInvoicesHistory ||
              location.pathname === Route.manager + Route.servicesFeatures ||
              location.pathname === Route.manager + Route.servicesIntegrations
            ? SIDEBAR_MENU_PATH.SERVICES
            : SIDEBAR_MENU_PATH.NONE
    );

    const { collapseSidebar, expandSidebar, upsertUsersettingSidebar } = useSidebarFunctions();

    useEffect(() => {
        const hasUserBeenSet = !isEmpty(navStore.user);
        if (
            !hasInitialized &&
            hasUserBeenSet &&
            (authStore.user?.role === Role.MANAGER ||
                (authStore.user?.role === Role.ECADMIN && authStore.user?.availablePortalRoles.length === 0))
        ) {
            const isSidebarCollapsedUserSetting = navStore.user.isSidebarCollapsedUserSetting;

            uiStore.setIsSidebarActive(true);

            // Initial Setting of UMS.UserSetting - "PORTAL.IS_SIDEBAR_COLLAPSED"
            if (!isSidebarCollapsedUserSetting) {
                upsertUsersettingSidebar(false);
                uiStore.expandSidebar();
            } else if (isSidebarCollapsedUserSetting.value === "true") {
                uiStore.collapseSidebar();
            } else if (isSidebarCollapsedUserSetting.value === "false") {
                uiStore.expandSidebar();
            }
        }

        return () => {
            setHasInitialized(false);
        };
    }, [
        hasInitialized,
        navStore.user.isSidebarCollapsedUserSetting,
        uiStore,
        authStore.user?.role,
        navStore.user,
        upsertUsersettingSidebar,
        authStore.user?.availablePortalRoles.length
    ]);

    useEffect(() => {
        // Initial Setting of active menu path
        if (location.pathname === Route.manager + Route.owners) {
            uiStore.setCurrentSidebarMenuPath(SIDEBAR_MENU_PATH.OWNERS);
        } else if (location.pathname === Route.manager + Route.realestates) {
            uiStore.setCurrentSidebarMenuPath(SIDEBAR_MENU_PATH.REALESTATES);
        } else if (location.pathname === Route.manager + Route.managerDashboard) {
            uiStore.setCurrentSidebarMenuPath(SIDEBAR_MENU_PATH.MANAGERDASHBOARD);
        } else if (location.pathname === Route.customerusers) {
            uiStore.setCurrentSidebarMenuPath(SIDEBAR_MENU_PATH.SETTINGS_CUSTOMERUSERS);
        } else if (location.pathname === Route.tileAdministration) {
            uiStore.setCurrentSidebarMenuPath(SIDEBAR_MENU_PATH.SETTINGS_TILE_ADMINISTRATION);
        } else if (location.pathname === Route.easycontactsettings) {
            uiStore.setCurrentSidebarMenuPath(SIDEBAR_MENU_PATH.SETTINGS_EASYCONTACT);
        } else if (location.pathname === Route.ticketingsettings) {
            uiStore.setCurrentSidebarMenuPath(SIDEBAR_MENU_PATH.SETTINGS_TICKETING);
        } else if (location.pathname === Route.theming) {
            uiStore.setCurrentSidebarMenuPath(SIDEBAR_MENU_PATH.SETTINGS_THEMING);
        } else if (location.pathname === Route.agendaTemplate) {
            uiStore.setCurrentSidebarMenuPath(SIDEBAR_MENU_PATH.SETTINGS_AGENDATEMPLATE);
        } else if (location.pathname === Route.portalDemo) {
            uiStore.setCurrentSidebarMenuPath(SIDEBAR_MENU_PATH.SETTINGS_PORTAL_DEMO);
        } else if (
            location.pathname === Route.manager + Route.servicesCostsAndInvoicesOverview ||
            location.pathname === Route.manager + Route.servicesCostsAndInvoicesCurrentMonth ||
            location.pathname === Route.manager + Route.servicesCostsAndInvoicesHistory
        ) {
            uiStore.setCurrentSidebarMenuPath(SIDEBAR_MENU_PATH.SERVICES_COSTS_AND_INVOICES);
        } else if (
            location.pathname === Route.manager + Route.servicesFeatures ||
            location.pathname === Route.manager + Route.servicesIntegrations
        ) {
            uiStore.setCurrentSidebarMenuPath(SIDEBAR_MENU_PATH.SERVICES_FEATURE_AND_INTEGRATION);
        }
        // Only run one time on initial load
        // eslint-disable-next-line
    }, [location.pathname, uiStore]);

    const sidebarNavigationLinksTop =
        authStore.user?.role === Role.ECADMIN && authStore.user?.availablePortalRoles.length === 0
            ? []
            : [
                  {
                      to: selectRoute(Route.ticketDetails, authStore.user?.role, {
                          ticketnumber: ticketStore.NEW_TICKET_ID
                      }),
                      label: t("screens.tickets.new_ticket"),
                      icon: UI.SVGIcon.Plus,
                      menuPath: SIDEBAR_MENU_PATH.NEW_TICKET,
                      isDisplayed: navStore.hasFeatureTicketing,
                      backgroundColorDarker: true
                  },
                  {
                      to: selectRoute(Route.managerDashboard, authStore.user?.role),
                      label: t("labels.dashboard"),
                      icon: UI.SVGIcon.DashboardSidebar,
                      menuPath: SIDEBAR_MENU_PATH.MANAGERDASHBOARD,
                      isDisplayed: true
                  },
                  {
                      to: selectRoute(Route.tickets, authStore.user?.role),
                      label: t("screens.tickets.sidebar_title"),
                      icon: UI.SVGIcon.ListView,
                      menuPath: SIDEBAR_MENU_PATH.TICKETS,
                      isDisplayed: navStore.hasFeatureTicketing
                  },
                  {
                      to: selectRoute(Route.creditors, authStore.user?.role),
                      label: t("screens.kredi_flow.sidebar_title"),
                      icon: UI.SVGIcon.CredIcon,
                      menuPath: SIDEBAR_MENU_PATH.KREDI_FLOW,
                      isDisplayed: navStore.hasFeatureKrediFlow
                  },
                  {
                      to: selectRoute(Route.owners, authStore.user?.role),
                      label: t("screens.owners.title"),
                      icon: UI.SVGIcon.Owners,
                      menuPath: SIDEBAR_MENU_PATH.OWNERS,
                      isDisplayed: true
                  },
                  {
                      to: selectRoute(Route.realestates, authStore.user?.role),
                      label: t("screens.realestates.title"),
                      icon: UI.SVGIcon.Realestates,
                      menuPath: SIDEBAR_MENU_PATH.REALESTATES,
                      isDisplayed: true
                  }
              ];

    const sidebarNavigationLinksSettings = [];

    if (authStore.user?.availableRoles) {
        if (authStore.user.availableRoles.includes(Role.ADMINISTRATOR)) {
            sidebarNavigationLinksSettings.push({
                label: t("screens.account.manage_users"),
                to: Route.customerusers,
                icon: UI.SVGIcon.ProfileDashboard,
                menuPath: SIDEBAR_MENU_PATH.SETTINGS_CUSTOMERUSERS
            });
        }

        if (
            authStore.user.availableRoles.includes(Role.ADMINISTRATOR) ||
            authStore.user.availableRoles.includes(Role.ECADMIN)
        ) {
            sidebarNavigationLinksSettings.push({
                label: t("sidebar.settings.design"),
                to: Route.theming,
                icon: UI.SVGIcon.Brush,
                menuPath: SIDEBAR_MENU_PATH.SETTINGS_THEMING
            });
        }

        if (authStore.user.availableRoles.includes(Role.ADMINISTRATOR)) {
            sidebarNavigationLinksSettings.push({
                label: `${t("sidebar.settings.realestate_dashboard_1")}${uiStore.isMobile ? "" : "-"}${t(
                    "sidebar.settings.realestate_dashboard_2"
                )}`,
                to: Route.tileAdministration,
                icon: UI.SVGIcon.DashboardSidebar,
                menuPath: SIDEBAR_MENU_PATH.SETTINGS_TILE_ADMINISTRATION
            });
        }

        if (authStore.user.availableRoles.includes(Role.ECADMIN)) {
            sidebarNavigationLinksSettings.push({
                label: t("sidebar.settings.easycontact"),
                to: Route.easycontactsettings,
                icon: UI.SVGIcon.EasyContact,
                menuPath: SIDEBAR_MENU_PATH.SETTINGS_EASYCONTACT
            });
        }

        if (authStore.user.availableRoles.includes(Role.ADMINISTRATOR)) {
            sidebarNavigationLinksSettings.push({
                label: t("sidebar.settings.agenda_template"),
                to: Route.agendaTemplate,
                icon: UI.SVGIcon.SortedList,
                menuPath: SIDEBAR_MENU_PATH.SETTINGS_AGENDATEMPLATE
            });
        }

        if (authStore.user.availableRoles.includes(Role.ADMINISTRATOR)) {
            sidebarNavigationLinksSettings.push({
                label: t("sidebar.settings.portal_demo"),
                to: Route.portalDemo,
                icon: UI.SVGIcon.Computer,
                menuPath: SIDEBAR_MENU_PATH.SETTINGS_PORTAL_DEMO
            });
        }

        if (authStore.user.availableRoles.includes(Role.ADMINISTRATOR)) {
            sidebarNavigationLinksSettings.push({
                label: t("sidebar.settings.userratingsportal"),
                to: Route.userRatingsPortal,
                icon: UI.SVGIcon.UserFeedback,
                menuPath: SIDEBAR_MENU_PATH.SETTINGS_USER_FEEDBACK
            });
        }

        if (authStore.user.availableRoles.includes(Role.ADMINISTRATOR) && navStore.hasFeatureTicketing) {
            sidebarNavigationLinksSettings.push({
                label: t("sidebar.settings.ticketing"),
                to: Route.ticketingsettings,
                icon: UI.SVGIcon.ListView,
                menuPath: SIDEBAR_MENU_PATH.SETTINGS_TICKETING
            });
        }
    }

    const sidebarNavigationLinksServices = authStore.user?.hasOnlyEcAdminRole
        ? []
        : [
              {
                  to: selectRoute(Route.servicesFeatures, authStore.user?.role),
                  label: t("sidebar.services.features_and_integrations"),
                  icon: UI.SVGIcon.Switcher,
                  menuPath: SIDEBAR_MENU_PATH.SERVICES_FEATURE_AND_INTEGRATION
              },
              {
                  to: selectRoute(Route.servicesCostsAndInvoicesOverview, authStore.user?.role),
                  label: t("sidebar.services.costs_and_invoices"),
                  icon: UI.SVGIcon.FinancesSidebar,
                  menuPath: SIDEBAR_MENU_PATH.SERVICES_COSTS_AND_INVOICES
              }
          ];

    const onToggleSidebar = useCallback(() => {
        if (uiStore.isSidebarCollapsed) {
            expandSidebar();
        } else {
            collapseSidebar();
        }
    }, [collapseSidebar, expandSidebar, uiStore.isSidebarCollapsed]);

    const onClickSidebarMenuPoint = useCallback(
        (menuPath: SIDEBAR_MENU_PATH) => () => {
            if (uiStore.currentSidebarMenuPath !== menuPath) {
                uiStore.setCurrentSidebarMenuPath(menuPath);
            }

            const isSubpathOfSettings =
                menuPath.includes(SIDEBAR_MENU_PATH.SETTINGS) && menuPath !== SIDEBAR_MENU_PATH.SETTINGS;

            const isSubpathOfServices =
                menuPath.includes(SIDEBAR_MENU_PATH.SERVICES) && menuPath !== SIDEBAR_MENU_PATH.SERVICES;

            if (!isSubpathOfSettings && !isSubpathOfServices) {
                setActiveSidebarMenuBottomKey(menuPath);
            }
        },
        [uiStore]
    );

    const SidebarMenuPoint = (props: any) => {
        const tooltip = (
            <span className={uiStore.isSidebarCollapsed ? styles.TooltipText : "d-none"}>{props.label}</span>
        );

        const label = (
            <span className={`ml-2 ${styles.SidebarLinkLabel} ${uiStore.isSidebarCollapsed ? "d-none" : ""}`}>
                {props.label}
            </span>
        );

        const renderCollapsedTooltip = useMemo(
            () => (
                <UI.Tooltip id="sidebarTooltip" className={styles.CollapsedTooltipText}>
                    {props.label}
                </UI.Tooltip>
            ),
            [props.label]
        );

        return (
            <div
                className={`${styles.SidebarIconTitleContainer} ${styles.TooltipContainer} d-flex align-items-center ${
                    uiStore.isMobile ? styles.Mobile : ""
                }`}
                onClick={onClickSidebarMenuPoint(props.menuPath)}
            >
                {uiStore.isSidebarCollapsed ? (
                    <UI.OverlayTrigger key={"sidebarTooltip2"} placement="right" overlay={renderCollapsedTooltip}>
                        <div className={styles.SidebarLinkIcon}>
                            <UI.Icon icon={props.icon} className={styles.SidebarLinkIcon} />
                        </div>
                    </UI.OverlayTrigger>
                ) : (
                    <>
                        <UI.Icon icon={props.icon} className={styles.SidebarLinkIcon} />
                        {tooltip}
                        {label}
                    </>
                )}
            </div>
        );
    };

    const SidebarNavigationItem = (props: any) => {
        return (
            <LinkContainer
                className={`${props.className} ${props.isCurrentMenuPathActive ? styles.CurrentMenuPath : ""} ${
                    uiStore.isMobile && uiStore.isSidebarCollapsed ? "d-none" : ""
                }`}
                to={props.to}
                onClick={props.onClick}
            >
                <UI.Nav.Link active={false}>
                    <SidebarMenuPoint label={props.label} menuPath={props.menuPath} icon={props.icon} />
                </UI.Nav.Link>
            </LinkContainer>
        );
    };

    const displayLogo =
        authStore.isLoggedIn && (authStore.user?.role === Role.MANAGER || authStore.user?.role === Role.ECADMIN);

    const displayMenuPointSettings =
        authStore.user?.availableRoles &&
        (authStore.user.availableRoles.includes(Role.ADMINISTRATOR) ||
            authStore.user.availableRoles.includes(Role.ECADMIN));

    const displayMenuPointServices =
        authStore.user?.availableRoles && authStore.user.availableRoles.includes(Role.ADMINISTRATOR);

    const sidebarRef = useRef<HTMLDivElement>(null);
    const [sidebarOverflow, setSidebarOverflow] = useState(false);

    const checkOverflow = () => {
        if (sidebarRef.current) {
            const heightOfSidebarLink = 52;
            const isOverflowing =
                sidebarRef.current.scrollHeight + heightOfSidebarLink > sidebarRef.current.clientHeight;
            setSidebarOverflow(isOverflowing);
        }
    };

    useEffect(() => {
        const currentSidebarRef = sidebarRef.current;
        checkOverflow();

        const resizeObserver = new ResizeObserver(checkOverflow);

        if (currentSidebarRef) {
            resizeObserver.observe(currentSidebarRef);
        }

        return () => {
            if (currentSidebarRef) {
                resizeObserver.unobserve(currentSidebarRef);
            }
        };
    }, []);

    return (
        <>
            {displayLogo ? (
                <div
                    id="SidebarLogo"
                    style={{
                        display:
                            (uiStore.isMobile && uiStore.sidebarWidth === 0) || !uiStore.isSidebarLogoDisplayed
                                ? "none"
                                : "block"
                    }}
                >
                    <Link
                        to={
                            authStore.user?.role === Role.ECADMIN && authStore.user?.availablePortalRoles.length === 0
                                ? Route.manager + Route.managerDashboard
                                : selectRoute(Route.managerDashboard, authStore.user?.role)
                        }
                    >
                        <img
                            src={NetworkConfig.brandAssets.logoImage}
                            className={`${styles.SidebarLogoImage} ${uiStore.isMobile ? styles.Mobile : ""}`}
                            alt="SidebarLogo"
                        />
                    </Link>
                </div>
            ) : undefined}

            <div
                id="SidebarContainer"
                className={`${styles.Sidebar} d-flex flex-column ${uiStore.isMobile ? styles.Mobile : ""}`}
                style={{
                    width: uiStore.isMobile
                        ? `${uiStore.sidebarWidth}${sidebarWidthUnit.mobile}`
                        : `${uiStore.sidebarWidth}${sidebarWidthUnit.desktop}`,
                    overflow: uiStore.sidebarWidth === 0 ? "hidden" : "visible"
                }}
            >
                <div
                    id="SidebarLinks"
                    ref={sidebarRef}
                    className={`${
                        sidebarOverflow && !uiStore.isSidebarCollapsed
                            ? `${styles.SidebarLinks} d-flex flex-column h-100`
                            : sidebarOverflow && uiStore.isSidebarCollapsed
                            ? `${styles.SidebarLinksCollapsed} d-flex flex-column h-100`
                            : "d-flex flex-column h-100"
                    }`}
                    style={{
                        marginTop: uiStore.isMobile
                            ? `${uiStore.getHeaderHeight()}px`
                            : `${uiStore.getHeaderHeight() + uiStore.navigationBarHeight}px`
                    }}
                >
                    <div className="SidebarLinksTop mb-auto">
                        <UI.Nav className={styles.SidebarNavigation}>
                            {sidebarNavigationLinksTop.map((navLink, index) => {
                                if (navLink.isDisplayed) {
                                    return (
                                        <SidebarNavigationItem
                                            key={`item-${index}`}
                                            label={navLink.label}
                                            to={navLink.to}
                                            icon={navLink.icon}
                                            className={`${styles.SidebarNavigationItem} ${
                                                navLink.backgroundColorDarker ? styles.BackgroundColorDarker : ""
                                            }`}
                                            menuPath={navLink.menuPath}
                                            isCurrentMenuPathActive={
                                                navLink.menuPath === uiStore.currentSidebarMenuPath
                                            }
                                        />
                                    );
                                } else {
                                    return undefined;
                                }
                            })}
                        </UI.Nav>
                    </div>
                    <div className="SidebarLinksBottom">
                        <UI.Accordion
                            defaultActiveKey={
                                authStore.user?.role === Role.ECADMIN &&
                                authStore.user?.availablePortalRoles.length === 0
                                    ? SIDEBAR_MENU_PATH.SETTINGS
                                    : activeSidebarMenuBottomKey
                            }
                            activeKey={
                                authStore.user?.role === Role.ECADMIN &&
                                authStore.user?.availablePortalRoles.length === 0
                                    ? SIDEBAR_MENU_PATH.SETTINGS
                                    : activeSidebarMenuBottomKey
                            }
                            flush
                        >
                            {displayMenuPointServices ? (
                                <UI.Accordion.Item eventKey={SIDEBAR_MENU_PATH.SERVICES}>
                                    <UI.Accordion.Header className={styles.AccordionHeader}>
                                        <SidebarMenuPoint
                                            label={t("sidebar.services.title")}
                                            menuPath={SIDEBAR_MENU_PATH.SERVICES}
                                            icon={UI.SVGIcon.Puzzle}
                                        />
                                    </UI.Accordion.Header>
                                    <UI.Accordion.Body>
                                        <UI.Nav className={styles.SidebarSubNavigation}>
                                            {sidebarNavigationLinksServices.map((navLink, index) => (
                                                <SidebarNavigationItem
                                                    key={`item-${index}`}
                                                    label={navLink.label}
                                                    to={navLink.to}
                                                    icon={navLink.icon}
                                                    className={styles.SidebarSubNavigationItem}
                                                    menuPath={navLink.menuPath}
                                                    isCurrentMenuPathActive={
                                                        navLink.menuPath === uiStore.currentSidebarMenuPath
                                                    }
                                                />
                                            ))}
                                        </UI.Nav>
                                    </UI.Accordion.Body>
                                </UI.Accordion.Item>
                            ) : undefined}
                            {displayMenuPointSettings ? (
                                <UI.Accordion.Item eventKey={SIDEBAR_MENU_PATH.SETTINGS}>
                                    <UI.Accordion.Header className={styles.AccordionHeader}>
                                        <SidebarMenuPoint
                                            label={t("sidebar.settings.title")}
                                            menuPath={SIDEBAR_MENU_PATH.SETTINGS}
                                            icon={UI.SVGIcon.Cogwheel}
                                        />
                                    </UI.Accordion.Header>
                                    <UI.Accordion.Body>
                                        <UI.Nav className={styles.SidebarSubNavigation}>
                                            {sidebarNavigationLinksSettings.map((navLink, index) => (
                                                <SidebarNavigationItem
                                                    key={`item-${index}`}
                                                    label={navLink.label}
                                                    to={navLink.to}
                                                    icon={navLink.icon}
                                                    className={styles.SidebarSubNavigationItem}
                                                    menuPath={navLink.menuPath}
                                                    isCurrentMenuPathActive={
                                                        navLink.menuPath === uiStore.currentSidebarMenuPath
                                                    }
                                                />
                                            ))}
                                        </UI.Nav>
                                    </UI.Accordion.Body>
                                </UI.Accordion.Item>
                            ) : undefined}
                        </UI.Accordion>
                        <div className={styles.SidebarCollapseIcon} onClick={onToggleSidebar}>
                            <UI.Icon
                                icon={uiStore.isSidebarCollapsed ? UI.SVGIcon.ChevronRight : UI.SVGIcon.ChevronLeft}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export const Sidebar = Sentry.withProfiler(observer(SidebarBase));
