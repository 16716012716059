import { useCallback, useContext } from "react";
import { UI } from "@wwimmo/ui";
import * as Sentry from "@sentry/react";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "src/stores/RootStore";
import styles from "./InvoiceAccounting.module.css";
import { IAccounting } from "src/stores/krediflow/InvoiceTypes";
import { ErpType } from "src/network/User";
import { RealestateField } from "./RealestateField";
import { AccountField } from "./AccountField";
import { RealestateChangedWarningModal } from "./RealestateChangedWarningModal/RealestateChangedWarningModal";
import { CostcenterField } from "./CostcenterField";
import { BookingTextField } from "./BookingTextField";
import { AmountField } from "./AmountField";
import { QuantityField } from "./QuantityField";
import { ExtraCostDateField } from "./ExtraCostDateField";

interface InvoiceAccountingProperties {
    accounting: IAccounting;
    accountingIndex: number;
}

const InvoiceAccountingBase = (props: InvoiceAccountingProperties) => {
    const { invoiceStore, authStore } = useContext(RootStoreContext);

    const { accountingIndex } = props;

    const removeAccountingRow = useCallback(
        (index: number) => () => {
            invoiceStore.currentInvoice?.removeAccountingRow(index);
        },
        [invoiceStore]
    );

    const accountField = <AccountField accountingRowIndex={accountingIndex} />;
    const costCenterField = <CostcenterField accountingRowIndex={accountingIndex} />;
    const bookingTextField = <BookingTextField accountingRowIndex={accountingIndex} />;

    const amountField = <AmountField accountingRowIndex={accountingIndex} />;

    const removeAccountingRowIcon =
        accountingIndex > 0 ? (
            <div className={styles.RemoveAccountingRowIcon} onClick={removeAccountingRow(accountingIndex)}>
                <UI.Icon icon={UI.SVGIcon.Close} color={"#970000"} size={25} />
            </div>
        ) : (
            <></>
        );

    const quantityField = <QuantityField accountingRowIndex={accountingIndex} />;
    const extraCostDateField = <ExtraCostDateField accountingRowIndex={accountingIndex} />;

    const erpType = authStore.user?.erpType;

    const accountingColumnsIT2 = (
        <>
            {/* First Row (12 Columns) */}
            <UI.Col lg={invoiceStore.currentInvoice?.canEditAccountingCostcenterField(accountingIndex) ? 3 : 6}>
                {accountField}
            </UI.Col>
            <UI.Col
                className={
                    invoiceStore.currentInvoice?.canEditAccountingCostcenterField(accountingIndex) ? "" : "d-none"
                }
                lg={3}
            >
                {costCenterField}
            </UI.Col>
            <UI.Col lg={3}>{bookingTextField}</UI.Col>
            <UI.Col lg={2}>{amountField}</UI.Col>
            <UI.Col lg={1}>{removeAccountingRowIcon}</UI.Col>
            {/* Second Row (12 Columns) */}
            <UI.Col lg={2} className="d-flex align-items-end">
                {quantityField}
            </UI.Col>
            <UI.Col lg={2}>{extraCostDateField}</UI.Col>
        </>
    );

    const accountingColumnsRIMO = (
        <>
            {/* First Row (12 Columns) */}
            <UI.Col lg={3}>{<RealestateField accountingRowIndex={accountingIndex} />}</UI.Col>
            <UI.Col lg={3}>{accountField}</UI.Col>
            <UI.Col lg={3}>{bookingTextField}</UI.Col>
            <UI.Col lg={2}>{amountField}</UI.Col>
            <UI.Col lg={1}>{removeAccountingRowIcon}</UI.Col>
            {/* Second Row (12 Columns) */}
            <UI.Col lg={2} className="d-flex align-items-end">
                {quantityField}
            </UI.Col>
            <UI.Col lg={2}>{extraCostDateField}</UI.Col>
        </>
    );

    const accountingValuationErrorString =
        invoiceStore.currentInvoice?.getAccountingValuationErrorString(accountingIndex);

    return (
        <>
            {<RealestateChangedWarningModal />}
            <UI.Row className={`${styles.AccountingRowContainer} ${accountingIndex > 0 ? "mt-2" : ""}`}>
                {erpType === ErpType.IT2
                    ? accountingColumnsIT2
                    : erpType === ErpType.RIMO
                    ? accountingColumnsRIMO
                    : undefined}
            </UI.Row>
            {accountingValuationErrorString ? (
                <div style={{ textAlign: "right" }}>
                    <span className="error-text">{accountingValuationErrorString}</span>
                </div>
            ) : (
                ""
            )}
        </>
    );
};

export const InvoiceAccounting = Sentry.withProfiler(observer(InvoiceAccountingBase));
