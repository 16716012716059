import React, { useContext, useCallback, useEffect, useState } from "react";
import {
    GetManagerNews_newsitems as newsitems,
    GetManagerNews_newsstatus as newsstatus
} from "src/api/generated/GetManagerNews";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import { Route, selectRoute } from "src/config/routes";
import { observer } from "mobx-react-lite";
import { UI, ScreenSize } from "@wwimmo/ui";
import { useParams } from "react-router";
import { getDate } from "src/utils/Date";
import { NewsState } from "src/api/news";
import styles from "./ManagerNewsList.module.css";

interface ManagerNewsListProps {
    newsItems: newsitems[];
    newsStatus: newsstatus[];
}

interface Params {
    realestateid: string;
}

const ManagerNewsList = (props: ManagerNewsListProps) => {
    const { t } = useTranslation();
    const { uiStore, navStore, authStore } = useContext(RootStoreContext);
    const { realestateid } = useParams<Params>();

    const listIdentifier = `managernewslist-${realestateid}`;

    if (!navStore.listMap.has(listIdentifier)) {
        navStore.setListMapValue(listIdentifier, {});
    }

    const listParameters = navStore.getListMapValue(listIdentifier);

    const [newsstatus, setNewsstatus] = useState<Map<number | null, { label: string }>>();

    useEffect(() => {
        if (props.newsItems) {
            setNewsstatus(getEnumerationMap(props.newsStatus));
        }
    }, [props.newsItems, props.newsStatus]);

    const onRowClick = useCallback(() => {
        navStore.setListMapValue(
            listIdentifier,
            listParameters
                ? { ...listParameters, scrollPosition: window.pageYOffset }
                : { scrollPosition: window.pageYOffset }
        );
    }, [navStore, listIdentifier, listParameters]);

    const onPageChanged = useCallback(
        (currentPage: number) => {
            navStore.setListMapValue(
                listIdentifier,
                listParameters
                    ? { ...listParameters, scrollPosition: window.pageYOffset, paginationPage: currentPage }
                    : { scrollPosition: window.pageYOffset, paginationPage: currentPage }
            );
        },
        [navStore, listIdentifier, listParameters]
    );

    const getEnumerationMap = (enumerations: Array<newsstatus>) => {
        const enumerationMap = new Map();

        enumerations.forEach((enumeration) => {
            if (enumeration.key !== null) {
                enumerationMap.set(enumeration.key, {
                    label: enumeration.label
                });
            }
        });

        return enumerationMap;
    };

    const renderItem = useCallback(
        (newsitem: newsitems, screenSize: ScreenSize) => {
            let listRows: React.ReactElement[] = [];
            const newsStatus = newsstatus?.get(newsitem.state)?.label;

            let link: string | undefined = selectRoute(Route.realestateNewsEdit, authStore.user?.role, {
                realestateid: realestateid,
                newsid: newsitem.id
            });

            let stateColor = "orange";

            switch (newsitem.state) {
                case NewsState.Published:
                    stateColor = "green";
                    link = selectRoute(Route.realestateNewsPreview, authStore.user?.role, {
                        realestateid: realestateid,
                        newsid: newsitem.id
                    });
                    break;
            }

            switch (screenSize) {
                case ScreenSize.DESKTOP:
                    listRows = [
                        <UI.List.Row key={"r-1"}>
                            <UI.List.Cell
                                key={"c-1"}
                                colspan={0.75}
                                value={
                                    <>
                                        <div className={styles.NewsTitle}> {newsitem.news[0].title}</div>
                                        <div className={styles.NewsItemDate}>{getDate(newsitem.timestamp)}</div>
                                    </>
                                }
                            />
                            <UI.List.Cell
                                key={"c-2"}
                                colspan={0.25}
                                value={<span>{newsStatus}</span>}
                                className={`${styles.NewsItemStatus} ${stateColor}`}
                            />
                        </UI.List.Row>
                    ];
                    break;
                case ScreenSize.MOBILE:
                    listRows = [
                        <UI.List.Row key={"r-1"}>
                            <UI.List.Cell
                                key={"c-1"}
                                colspan={0.65}
                                value={
                                    <>
                                        <div>{newsitem.news[0].title}</div>
                                        <div className={styles.NewsItemDate}>{getDate(newsitem.timestamp)}</div>
                                    </>
                                }
                            />
                            <UI.List.Cell
                                key={"c-2"}
                                colspan={0.35}
                                value={<span>{newsStatus}</span>}
                                className={`${styles.NewsItemStatus} ${stateColor}`}
                            />
                        </UI.List.Row>
                    ];
                    break;
            }

            return (
                <UI.List.Item
                    key={newsitem.id}
                    screenSize={screenSize}
                    rows={listRows}
                    to={link}
                    onClick={link ? onRowClick : undefined}
                    className={styles.NewsListItem}
                />
            );
        },
        [newsstatus, authStore.user?.role, onRowClick, realestateid]
    );

    return (
        <UI.List.BasicList
            screenSize={uiStore.isMobile ? ScreenSize.MOBILE : ScreenSize.DESKTOP}
            items={props.newsItems}
            renderItem={renderItem}
            scrollPosition={listParameters?.scrollPosition ?? undefined}
            itemsPerPage={10}
            initialPage={listParameters?.paginationPage ?? undefined}
            onPageChanged={onPageChanged}
            emptyListMessage={t("screens.realestate.dashboard.no_news_available")}
        />
    );
};

export default observer(ManagerNewsList);
