export interface ConfigSettings {
    env: "dev" | "test" | "prod";
    customerAuthSubdomain: string;
    authWebsiteUrl: string;
    authority: string;
    graphqlUrl: string;
    datafileUrl: string;
    uploadFileUrl: string;
    openThumbnailUrl: string;
    getCustomerPortalUrl: string;
    getTenancyIndexCsvFile: string;
    downloadAssemblyFiles: string;
    downloadCustomerInvoicedFiles: string;
    downloadAgendaItemFiles: string;
    downloadFileVersion2: string;
    openFileWithTokenUrl: string;
    brandAssets: {
        uploadLogo: string;
        uploadColors: string;
        logoImage: string;
        css: string;
    };
    sentryUrl: string;
    timeout: number;
    proxy: string;
    clientId: string;
    responseType: string;
    scope: string;
    upsertNewsUrl: string;
    insertNotificationUrl: string;
    getCitiesUrl: string;
    getStreetsInCityUrl: string;
    getStreetNumbersInStreetUrl: string;
    copyagendaitemsLastAssembly: string;
    copyagendaitemTemplates: string;
    systemHealthUrl: string;
    graphqlWssUrl: string;
    createIncidentUrl: string;
    createOrderUrl: string;
    checkIncidentCanBeCompletedUrl: string;
    closeIncidentUrl: string;
    assignIncidentToUserUrl: string;
    orderCloseUrl: string;
    updateIncidentPersonsUrl: string;
    concludeUserAssignedUrl: string;
    concludeRealestateAssignedUrl: string;
    orderRetrySubmission: string;
    yarowaDashboardDefaultUrl: string;
    activateService: string;
    deactivateService: string;
    getTicketingCostMonth: string;
    environment: string;
    sendManualEmailUrl: string;
    generateassemblypdf: string;
    updateUserStatusUrl: string;
    isMaintenanceActive: boolean;
    incidentArchive: string;
    // cidaas
    wwPortalClientId: string;
    cidaasBaseUrl: string;
    cidaasChangePasswordRessource: string;
    cidaasAuthorisationServerRessource: string;
    cidaasGetTokenRessource: string;
    cidaasEndSessionRessource: string;
    // krediflow
    extractDataSinglePdfUrl: string;
    createInvoiceUrl: string;
    approveOrRejectInvoiceUrl: string;
    concludeHumanTaskUrl: string;
    cancelInvoiceUrl: string;
}

const isLocal = window.location.host.indexOf("localhost") === 0;
const proxy = isLocal ? "https://localhost.wwportal.ch" : `${window.location.protocol}//${window.location.host}`;
const subdomain = isLocal ? "localhost" : window.location.host.split(".")[0];
const customerAuthSubdomain = isLocal ? "dev" : window.location.host.split(".")[0];
const wwCidaasBaseUrl = `${proxy}/api/cidaas-webhook`;

export const NetworkConfig = {
    graphqlUrl: "https://ww-prod-hasura-cidaas-wa.azurewebsites.net/v1/graphql",
    customerAuthSubdomain: customerAuthSubdomain,
    authWebsiteUrl: "https://auth.wwportal.ch",
    authority: `${proxy}/auth`,
    datafileUrl: `${proxy}/api/v2/files/open/`,
    uploadFileUrl: `${proxy}/api/v2/files/upload`,
    openThumbnailUrl: `${proxy}/api/v2/files/openthumbnail/`,
    getCustomerPortalUrl: `${proxy}/api/customer/getcustomerportalurl`,
    getTenancyIndexCsvFile: `${proxy}/api/customer/tenancyindex`,
    downloadAssemblyFiles: `${proxy}/api/portal/getzipassemblyfiles`,
    downloadCustomerInvoicedFiles: `${proxy}/api/portal/getcustomerinvoicedfiles`,
    downloadAgendaItemFiles: `${proxy}/api/portal/getzipagendaitemfiles`,
    activateService: `${proxy}/api/portal/activate_service/`,
    deactivateService: `${proxy}/api/portal/deactivate_service/`,
    downloadFileVersion2: `${proxy}/api/v2/files/download2`,
    openFileWithTokenUrl: `${proxy}/api/v2/files/open-with-token`,
    brandAssets: {
        uploadLogo: `${proxy}/api/v2/files/upload/logo/${subdomain}`,
        uploadColors: `${proxy}/api/v2/files/upload/css/${subdomain}`,
        logoImage: `${proxy}/api/designs/logo/brand.jpg?t=${new Date().getTime()}`,
        css: `${proxy}/api/designs/css/brand.css`
    },
    sentryUrl: "https://cc27df4003a645fa959a0fe62b47602a@o421140.ingest.sentry.io/5340447",
    proxy,
    clientId: "WWPortalClient",
    responseType: "code",
    scope: "offline_access wwimmo.mobile.core.api IdentityServerApi",
    upsertNewsUrl: `${proxy}/api/news/news`,
    insertNotificationUrl: `${proxy}/api/notification/eventqueue`,
    getCitiesUrl: `${proxy}/api/rest/common/cities`,
    getStreetsInCityUrl: `${proxy}/api/rest/common/streets`,
    getStreetNumbersInStreetUrl: `${proxy}/api/rest/common/streetnumbers`,
    copyagendaitemsLastAssembly: `${proxy}/api/rest/cond/copyagendaitems`,
    copyagendaitemTemplates: `${proxy}/api/rest/cond/copyagendaitemtemplates`,
    systemHealthUrl: `${proxy}/api/health`,
    graphqlWssUrl: "wss://ww-prod-hasura-wa.azurewebsites.net/v1/graphql",
    createIncidentUrl: `${proxy}/api/ticket/incident/create`,
    createOrderUrl: `${proxy}/api/ticket/order/create`,
    checkIncidentCanBeCompletedUrl: `${proxy}/api/ticket/incident/checksetcompleted`,
    closeIncidentUrl: `${proxy}/api/ticket/incident/closeticket`,
    assignIncidentToUserUrl: `${proxy}/api/ticket/incident/assignuser`,
    orderCloseUrl: `${proxy}/api/ticket/order/close`,
    updateIncidentPersonsUrl: `${proxy}/api/ticket/incident/updateincidentpersons`,
    concludeUserAssignedUrl: `${proxy}/api/ticket/incident/concludeuserassigned`,
    concludeRealestateAssignedUrl: `${proxy}/api/ticket/incident/concluderealestateassigned`,
    orderRetrySubmission: `${proxy}/api/ticket/order/retrysubmission`,
    yarowaDashboardDefaultUrl: "https://www-appenzell-pool.enable.jarowa.ch/dashboard",
    getTicketingCostMonth: `${proxy}/api/customer/getticketingcost`,
    sendManualEmailUrl: `${proxy}/api/ticket/incident/sendmanualemail`,
    generateassemblypdf: `${proxy}/api/portal/generateassemblypdf/`,
    incidentArchive: `${proxy}/api/ticket/incident/archive`,
    environment: "<placeholder>",
    wwPortalClientId: "<placeholder>",
    cidaasBaseUrl: "<placeholder>",
    cidaasChangePasswordRessource: "/users-srv/changepassword",
    cidaasEndSessionRessource: "/session/end_session",
    cidaasAuthorisationServerRessource: "/authz-srv/authz",
    cidaasGetTokenRessource: "/token-srv/token",
    updateUserStatusUrl: `${wwCidaasBaseUrl}/update-user-status`,
    isMaintenanceActive: false,
    extractDataSinglePdfUrl: `${proxy}/api/krediflow/extract-data-single-pdf`,
    createInvoiceUrl: `${proxy}/api/krediflow/createinvoice`,
    approveOrRejectInvoiceUrl: `${proxy}/api/krediflow/invoice/approve`,
    cancelInvoiceUrl: `${proxy}/api/krediflow/invoice/cancel`,
    concludeHumanTaskUrl: `${proxy}/api/krediflow/concludehumantask`
} as ConfigSettings;
