import { observable, action, runInAction, makeObservable } from "mobx";
import { ColorStyle } from "src/utils/Colors";
import { Announcement, Notification } from "src/types";
import { persist } from "mobx-persist";
import { RootStore } from "./RootStore";
import { MessageType } from "src/components/notifications/Notifier";
import { GetNewsDetailsById_newsitems_newsfiles as db_newsfiles } from "src/api/generated/GetNewsDetailsById";
import { apolloClientInstance } from "src/network/apolloClientInstance";
import { UpsertUsersetting, UpsertUsersettingVariables } from "src/api/generated/UpsertUsersetting";
import { UPSERT_USERSETTING } from "src/api/customer";
import { ums_usersettings_insert_input } from "src/api/generated/globalTypes";

const headerHeights = {
    mobile: 70,
    tablet: 80,
    desktop: 80
};

const sidebarWidth = {
    hidden: 0,
    collapsed: 50,
    expanded: 180,
    expandedMobile: 65
};

export const sidebarWidthUnit = {
    desktop: "px",
    mobile: "%"
};

export enum ScreenWidth {
    SM = 576,
    MD = 768,
    LG = 992,
    XL = 1200
}

export enum RealestatesListType {
    GRID,
    LIST
}

export enum SIDEBAR_MENU_PATH {
    NONE = "NONE",
    OWNERS = "OWNERS",
    MANAGERDASHBOARD = "MANAGERDASHBOARD",
    TICKETS = "TICKETS",
    KREDI_FLOW = "KREDI_FLOW",
    NEW_TICKET = "NEW_TICKET",
    REALESTATES = "REALESTATES",
    SETTINGS = "SETTINGS",
    SETTINGS_CUSTOMERUSERS = "SETTINGS_CUSTOMERUSERS",
    SETTINGS_THEMING = "SETTINGS_THEMING",
    SETTINGS_TILE_ADMINISTRATION = "SETTINGS_TILE_ADMINISTRATION",
    SETTINGS_EASYCONTACT = "SETTINGS_EASYCONTACT",
    SETTINGS_TICKETING = "SETTINGS_TICKETING",
    SETTINGS_AGENDATEMPLATE = "SETTINGS_AGENDATEMPLATE",
    SETTINGS_PORTAL_DEMO = "SETTINGS_PORTAL_DEMO",
    SETTINGS_USER_FEEDBACK = "SETTINGS_USER_FEEDBACK",
    SERVICES = "SERVICES",
    SERVICES_COSTS_AND_INVOICES = "SERVICES_COSTS_AND_INVOICES",
    SERVICES_FEATURE_AND_INTEGRATION = "SERVICES_FEATURE_AND_INTEGRATION"
}

export enum TICKET_VIEW_TYPE {
    LIST = "List",
    KANBAN = "Kanban"
}

// As defined by chrome dev tools
export enum SCREEN_SIZE {
    MOBILE_S,
    MOBILE_M,
    MOBILE_L,
    TABLET,
    LAPTOP,
    LAPTOP_L,
    DESKTOP
}

export const DEFAULT_INVOICE_PANE_WIDTH: number = 700;
export const DEFAULT_INVOICE_PANE_WIDTH_RATIO: number = 0.6;

export class UIStore {
    rootStore: RootStore;

    @observable showNavigationBar = true;
    @observable isMobile = false;
    @observable showSearchbar = false;
    @observable closeAppbar = false;
    @observable headerHeight = 80;
    @observable navigationBarHeight = 52;
    @observable backgroundColor = ColorStyle("white");
    @observable realestatesViewType = RealestatesListType.GRID;
    @observable financeListSelectionType: number | undefined = undefined;
    @observable financeListSelectionId: string | undefined = undefined;
    @observable financeListExpandedItems: string[] | undefined = undefined;
    @observable shouldShowLoginNotification: boolean = false;
    @observable notifications: Notification[] = [];
    @observable displayedNotifications: number[] = [];
    @observable showSearchAppbarButton: boolean = true;
    @observable showProfileAppbarButton: boolean = true;
    @observable hasNewNewsData: boolean = true;
    @observable hasNewAssembliesData: boolean = true;
    @observable rerenderNavigationBar: boolean = false;
    @persist("object") @observable currentAnnouncement: Announcement | undefined;
    @persist @observable wasAnnouncementDownloaded: boolean = false;
    @observable isSidebarActive: boolean = false;
    @observable contentWidth: number = 0;
    @observable sidebarWidth: number = sidebarWidth.hidden;
    @observable isSidebarCollapsed: boolean = false;
    @observable currentSidebarMenuPath: SIDEBAR_MENU_PATH = SIDEBAR_MENU_PATH.NONE;
    @observable isLegendDisplayed: boolean = true;
    @persist("object") @observable ticketListView: TICKET_VIEW_TYPE = TICKET_VIEW_TYPE.LIST;
    @observable isNewsInsertedEventLoaded: boolean = true;
    @observable isAddNewsDocumentClicked: boolean = false;
    @observable isCustomerUserReset: boolean = false;
    @observable dbNewsFiles: db_newsfiles[] = [];
    @observable tenantItemName: string | undefined = undefined;
    @observable isDemoModeTicketingActive: boolean = false;
    @observable currentScreenSize: SCREEN_SIZE = SCREEN_SIZE.DESKTOP;
    @observable invoiceFormContainerHeight: number = 0;
    @observable invoiceFormHeight: number = 0;
    @observable invoiceFilePreviewWidth: number = 0;
    @observable invoicePaneWidth: number = DEFAULT_INVOICE_PANE_WIDTH;
    @observable invoicePaneScreenSize: SCREEN_SIZE = SCREEN_SIZE.DESKTOP;
    @observable invoicePaneWidthRatio: number = 0;
    @observable splitPaneWidth: number = 0;
    @observable isResettingInvoicePaneWidth: boolean = false;
    @observable isHeaderDisplayed: boolean = true;
    @observable isSidebarLogoDisplayed: boolean = true;

    mobileWidth = 576;
    tabletWidth = 768;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        runInAction(() => {
            window.addEventListener("resize", this.onWindowResize);
            this.setIsMobile(window.innerWidth < 576);

            this.currentScreenSize = this.calculateScreenSize(window.innerWidth);

            this.setHeaderHeight(this.getHeaderHeight());
        });
        makeObservable(this);
    }

    getHeaderHeight = () => {
        if (window.innerWidth <= this.mobileWidth) {
            return headerHeights.mobile;
        } else if (window.innerWidth > this.mobileWidth && window.innerWidth <= this.tabletWidth) {
            return headerHeights.tablet;
        } else {
            return headerHeights.desktop;
        }
    };

    @action
    setInvoicePaneWidthRatio = (ratio: number) => {
        this.invoicePaneWidthRatio = ratio;
    };

    @action
    setSplitPaneWidth = (width: number) => {
        this.splitPaneWidth = width;
    };

    @action
    setIsResettingInvoicePaneWidth = (isResettingInvoicePaneWidth: boolean) => {
        this.isResettingInvoicePaneWidth = isResettingInvoicePaneWidth;
    };

    @action
    resetInvoicePaneWidth = () => {
        this.setIsResettingInvoicePaneWidth(true);
        this.setInvoicePaneWidth(DEFAULT_INVOICE_PANE_WIDTH);
        this.setIsResettingInvoicePaneWidth(false);
    };

    @action
    setInvoicePaneWidth = (width: number) => {
        this.invoicePaneWidth = width;
        this.invoicePaneScreenSize = this.calculateScreenSize(width);
        this.saveInvoicePaneWidthUserSetting(width);
    };

    @action
    saveInvoicePaneWidthUserSetting = async (invoicePaneWidth: number) => {
        let newInvoicePaneWidthRatio = invoicePaneWidth / this.splitPaneWidth;
        this.invoicePaneWidthRatio = newInvoicePaneWidthRatio;

        let success = false;

        try {
            const upsertAssemblyUserAuditObject: ums_usersettings_insert_input[] = [
                {
                    value: newInvoicePaneWidthRatio.toString(),
                    valuetype: 2,
                    key: "PORTAL.INVOICE_PANE_WIDTH_RATIO"
                }
            ];

            await apolloClientInstance.mutate<UpsertUsersetting, UpsertUsersettingVariables>({
                mutation: UPSERT_USERSETTING,
                variables: { objects: upsertAssemblyUserAuditObject }
            });
        } catch (error) {
            console.error("Error saving setting: ", error);
        }

        success = true;

        return success;
    };

    @action
    setInvoiceFormContainerHeight = (height: number) => {
        this.invoiceFormContainerHeight = height;
    };

    @action
    setInvoiceFormHeight = (height: number) => {
        this.invoiceFormHeight = height;
    };

    @action
    setInvoiceFilePreviewWidth = (width: number) => {
        this.invoiceFilePreviewWidth = width;
    };

    @action
    setShowProfileAppbarButton = (showProfileAppbarButton: boolean) => {
        this.showProfileAppbarButton = showProfileAppbarButton;
    };

    @action
    setShowSearchAppbarButton = (showSearchAppbarButton: boolean) => {
        this.showSearchAppbarButton = showSearchAppbarButton;
    };

    @action
    setSearchbar = (toggle: boolean) => {
        this.setHeaderHeight(toggle ? headerHeights.desktop : undefined);
        this.showSearchbar = toggle;
    };

    @action
    setIsMobile = (isMobile: boolean) => {
        this.isMobile = isMobile;
    };

    @action
    setHeaderHeight(overwriteHeight?: number) {
        this.headerHeight =
            overwriteHeight !== undefined
                ? overwriteHeight
                : this.isMobile
                ? headerHeights.mobile
                : headerHeights.desktop;
    }

    @action
    setCloseAppBar = (closeAppBar: boolean) => {
        this.closeAppbar = closeAppBar;
    };

    @action
    setBackgroundColor = (backgroundColor: string) => {
        this.backgroundColor = backgroundColor;
    };

    @action
    setFinanceListExpandedItems = (newList: string[] | undefined) => {
        this.financeListExpandedItems = newList;
    };

    @action
    setFinanceListSelectionId = (newSelectionId: string | undefined) => {
        this.financeListSelectionId = newSelectionId;
    };

    @action
    setRealestatesViewType = (newViewType: RealestatesListType) => {
        this.realestatesViewType = newViewType;
    };

    @action
    setShouldShowLoginNotification = (shouldShow: boolean) => {
        this.shouldShowLoginNotification = shouldShow;
    };

    @action
    setRerenderNavigationBar = (rerenderNavigationBar: boolean) => {
        this.rerenderNavigationBar = rerenderNavigationBar;
    };

    @action
    enqueueSnackbar = (note: Notification) => {
        this.notifications.push({
            key: new Date().getTime() + Math.random(),
            ...note
        });
    };

    @action
    removeSnackbar = (note: Notification) => {
        this.notifications = this.notifications.filter((not) => not.key !== note.key);
    };

    @action
    storeDisplayedNotification = (key: number) => {
        this.displayedNotifications = [...this.displayedNotifications, key];
    };

    @action
    private onWindowResize = () => {
        this.setIsMobile(window.innerWidth <= 768);

        runInAction(() => {
            this.currentScreenSize = this.calculateScreenSize(window.innerWidth);
        });

        this.setHeaderHeight(this.getHeaderHeight());
    };

    @action
    reset = () => {
        this.realestatesViewType = RealestatesListType.GRID;
        this.financeListSelectionType = undefined;
        this.financeListSelectionId = undefined;
        this.financeListExpandedItems = undefined;
        this.shouldShowLoginNotification = false;
        this.notifications = [];
        this.displayedNotifications = [];
        this.currentAnnouncement = undefined;
        this.wasAnnouncementDownloaded = false;
    };

    @action
    setCurrentAnnouncement = (announcement: Announcement | undefined) => {
        this.currentAnnouncement = announcement;
    };

    @action
    setWasAnnouncementDownloaded = (wasAnnouncementDownloaded: boolean) => {
        this.wasAnnouncementDownloaded = wasAnnouncementDownloaded;
    };

    @action
    setHasNewNewsData = (hasNewNewsEntry: boolean) => {
        this.hasNewNewsData = hasNewNewsEntry;
    };

    @action
    setShowNavigationBar = (showNavigationBar: boolean) => {
        this.showNavigationBar = showNavigationBar;
    };

    @action
    setHasNewAssembliesData = (hasNewAssembliesData: boolean) => {
        this.hasNewAssembliesData = hasNewAssembliesData;
    };

    @action
    setContentWidth = (contentWidth: number) => {
        this.contentWidth = contentWidth;
    };

    @action
    setSidebarWidth = (sidebarWidth: number) => {
        this.sidebarWidth = sidebarWidth;
    };

    @action
    expandSidebar = () => {
        if (this.isMobile) {
            this.sidebarWidth = sidebarWidth.expandedMobile;
        } else {
            this.sidebarWidth = sidebarWidth.expanded;
        }

        this.isSidebarCollapsed = false;
    };

    @action
    collapseSidebar = () => {
        if (this.isMobile) {
            this.sidebarWidth = sidebarWidth.hidden;
        } else {
            this.sidebarWidth = sidebarWidth.collapsed;
        }

        this.isSidebarCollapsed = true;
    };

    @action
    hideSidebar = () => {
        this.sidebarWidth = sidebarWidth.hidden;
    };

    @action
    setIsSidebarCollapsed = (isSidebarCollapsed: boolean) => {
        this.isSidebarCollapsed = isSidebarCollapsed;
    };

    @action
    setIsSidebarActive = (isSidebarActive: boolean) => {
        this.isSidebarActive = isSidebarActive;
    };

    @action
    setCurrentSidebarMenuPath = (currentSidebarMenuPath: SIDEBAR_MENU_PATH) => {
        this.currentSidebarMenuPath = currentSidebarMenuPath;
    };

    @action
    setIsLegendDisplayed = (isLegendDisplayed: boolean) => {
        this.isLegendDisplayed = isLegendDisplayed;
    };

    @action
    setTicketViewType = (ticketViewType: TICKET_VIEW_TYPE) => {
        this.ticketListView = ticketViewType;
    };

    @action
    setIsNewsInsertedEventLoaded = (isNewsInsertedEventLoaded: boolean) => {
        this.isNewsInsertedEventLoaded = isNewsInsertedEventLoaded;
    };

    @action
    printStatusMessage = (message: string, msgType: MessageType, milliseconds?: number) => {
        this.enqueueSnackbar({
            content: message,
            options: {
                type: msgType,
                autoClose: msgType === MessageType.ERROR ? false : milliseconds ? milliseconds : 3000
            }
        });
    };

    @action
    printTicketingErrorMessage = (message: React.ReactNode | string) => {
        this.enqueueSnackbar({
            content: message,
            options: {
                type: MessageType.ERROR,
                autoClose: false,
                className: "ticket-missing-information-status-message"
            }
        });
    };

    @action
    setIsAddNewsDocumentClicked = (isAddNewsDocumentClicked: boolean) => {
        this.isAddNewsDocumentClicked = isAddNewsDocumentClicked;
    };

    @action
    setIsCustomerUserReset = (isCustomerUserReset: boolean) => {
        this.isCustomerUserReset = isCustomerUserReset;
    };

    @action
    setDbNewsFiles = (dbNewsFiles: db_newsfiles[]) => {
        this.dbNewsFiles = dbNewsFiles;
    };

    @action
    setTenantItemName = (tenantItemName: string) => {
        this.tenantItemName = tenantItemName;
    };

    @action
    setIsDemoModeTicketingActive = (isDemoModeTicketingActive: boolean) => {
        this.isDemoModeTicketingActive = isDemoModeTicketingActive;
    };

    @action
    calculateScreenSize(width: number): SCREEN_SIZE {
        if (width <= 320) {
            return SCREEN_SIZE.MOBILE_S;
        } else if (width <= 425) {
            return SCREEN_SIZE.MOBILE_M;
        } else if (width <= 500) {
            return SCREEN_SIZE.MOBILE_L;
        } else if (width <= 768) {
            return SCREEN_SIZE.TABLET;
        } else if (width <= 1024) {
            return SCREEN_SIZE.LAPTOP;
        } else if (width <= 1440) {
            return SCREEN_SIZE.LAPTOP_L;
        } else {
            return SCREEN_SIZE.DESKTOP;
        }
    }

    @action
    setIsHeaderDisplayed = (isHeaderDisplayed: boolean) => {
        this.isHeaderDisplayed = isHeaderDisplayed;
    };

    @action
    setIsSidebarLogoDisplayed = (isSidebarLogoDisplayed: boolean) => {
        this.isSidebarLogoDisplayed = isSidebarLogoDisplayed;
    };
}
