import React, { useContext, useEffect, useState } from "react";
import * as Sentry from "@sentry/react";
import { observer } from "mobx-react-lite";
import { withRouter, useParams } from "react-router";
import { RootStoreContext } from "src/stores/RootStore";
import { NetworkConfig } from "src/network/NetworkConfig";
import { useTranslation } from "react-i18next";
import { Error } from "src/screens/error/Error";

interface Params {
    fileid: string;
}

const FileBase = () => {
    const { fileid } = useParams<Params>();
    const { authStore, uiStore } = useContext(RootStoreContext);
    const [fileUrl, setFileUrl] = useState<string | null>(null);
    const [hasError, setHasError] = useState<boolean>(false);

    const { t } = useTranslation();

    useEffect(() => {
        if (uiStore.sidebarWidth > 0) {
            uiStore.hideSidebar();
        }

        uiStore.setIsHeaderDisplayed(false);
        uiStore.setIsSidebarLogoDisplayed(false);

        return () => {
            uiStore.collapseSidebar();
            uiStore.setIsHeaderDisplayed(true);
            uiStore.setIsSidebarLogoDisplayed(true);
        };
    }, [uiStore.sidebarWidth, uiStore, uiStore.isHeaderDisplayed]);

    useEffect(() => {
        const fetchAndOpenFile = async () => {
            const accessToken = authStore.token;
            const tokenType = authStore.tokenType;
            try {
                const response = await fetch(`${NetworkConfig.openFileWithTokenUrl}/${fileid}`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `${tokenType} ${accessToken}`
                    }
                });

                if (!response.ok) {
                    console.error("Failed to fetch file", response.status, response.statusText);
                    setHasError(true);
                    return;
                }

                const blob = await response.blob();
                const fileURL = URL.createObjectURL(blob);

                setFileUrl(fileURL);
            } catch (error) {
                console.error("Error opening file:", error);
                setHasError(true);
            }
        };

        if (fileid) {
            fetchAndOpenFile();
        }

        // Cleanup the blob URL when component unmounts
        return () => {
            if (fileUrl) {
                URL.revokeObjectURL(fileUrl);
            }
        };

        // eslint-disable-next-line
    }, []);

    return (
        <div style={{ height: "100vh" }}>
            {fileUrl && !hasError && (
                <iframe
                    src={fileUrl}
                    style={{ width: "100%", height: "100%", border: "none", margin: "0px" }}
                    title="file-viewer"
                />
            )}
            {!fileUrl && !hasError && <p className="p-2">{`${t("screens.file.loading")}...`}</p>}
            {hasError && <Error message={t("screens.file.error_loading_file").toString()} />}
        </div>
    );
};

export const File = withRouter(Sentry.withProfiler(observer(FileBase)));
